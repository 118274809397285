html {
  overflow: scroll;
  overflow-x: hidden;
}
html, body {
  max-width: 100%;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 0;  /* Remove scrollbar space */
  background: transparent;  /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
  background: #FF0000;
}

.font {
    font-family: 'Inter', sans-serif;
}

.bgvideodiv {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%; 
    overflow: hidden;
  }
  
  .bgvideodiv video {
    min-width: 100%; 
    min-height: 100%; 
    width: auto;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }

  html,

.ocean {
  height: 5%;
  width: 100%;

}

.wave {
  background: url(../src/images/wave.svg) repeat-x;
  position: absolute;
  top: -198px;
  
  width: 6400px;
  height: 198px;
  animation: wave 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
  transform: translate3d(0, 0, 0);
}

.wave:nth-of-type(2) {
  top: -175px;
  animation: wave 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) -0.125s infinite, swell 7s ease -1.25s infinite;
  opacity: 1;
}

@keyframes wave {
  0% {
    margin-left: 0;
  }
  100% {
    margin-left: -1600px;
  }
}
@keyframes swell {
  0%,
	100% {
    transform: translate3d(0, -25px, 0);
  }
  50% {
    transform: translate3d(0, 5px, 0);
  }
}
.endWave{
	display:none;
}




.glow-on-hover {
  width: 220px;
  height: 50px;
  border: none;
  outline: none;
  color: #fff;
  background: #111;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 10px;
}

.glow-on-hover:before {
  content: '';
  background: linear-gradient(45deg, #0066ff, #0026ff, #2600ffa6, #003cff, #0026ff, #002bff, #070041, #3700ff, #006eff);  
  position: absolute;
  top: -2px;
  left:-2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing 20s linear infinite;
  opacity: 0;
  transition: opacity .3s ease-in-out;
  border-radius: 10px;
}

.glow-on-hover:active {
  color: #000
}

.glow-on-hover:active:after {
  background: transparent;
}

.glow-on-hover:hover:before {
  opacity: 1;
}

.glow-on-hover:after {
  z-index: -1;
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: #111;
  left: 0;
  top: 0;
  border-radius: 10px;
}

@keyframes glowing {
  0% { background-position: 0 0; }
  50% { background-position: 400% 0; }
  100% { background-position: 0 0; }
}

.neon {
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;
  position: relative;
  overflow: hidden;
  filter: brightness(200%);
}

.text-metaverse {
  background-color: black;
  color: white;
  font-weight: bold;
  font-family: sans-serif;
  position: relative;
  user-select: none;
}

.text-metaverse::before {
  content: attr(data-text);
  position: absolute;
  color: white;
  filter: blur(0.02em);
  mix-blend-mode: difference;
}

.gradient-metaverse {
  position: absolute;
  background: linear-gradient(45deg, white, blue, blue, blue, purple);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  mix-blend-mode: multiply;
}

.spotlight {
  position: absolute;
  top: -100%;
  left: -100%;
  right: 0;
  bottom: 0;
  background: 
      radial-gradient(
          circle,
          white,
          transparent 25%
      ) center / 25% 25%,
      radial-gradient(
          circle,
          white,
          black 25%
      ) center / 12.5% 12.5%;
  animation: light 5s linear infinite;
  mix-blend-mode: color-dodge;
}

@keyframes light {
  to {
      transform: translate(50%, 50%);
  }
}


model-viewer#reveal {
  height: 80%;
  width: 100%;
  --poster-color: black;
  --progress-mask: black;
}